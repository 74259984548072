
import { defineComponent , watch , ref ,Ref , PropType , onMounted , reactive ,   } from 'vue'
import { conversaoPacotesConsultor } from '@/services/BusinessInteligence'
import { FilterProps } from '../models'
import { useStore } from 'vuex';
import ApexCharts from 'apexcharts';
import { ContentLoader } from 'vue-content-loader';
import LoadingNameGrafico from '../components/LoadingNameGrafico.vue'
import {fileName, heightDinamicoFunc, titleChart} from '../ultils'

export default defineComponent({
name: 'Conversao-pacotes-Consultor',
components: {
    ContentLoader,
    LoadingNameGrafico
},
props: {   
    filter: {  
        type: Object as PropType<FilterProps>,
        required: true      
    },
},
setup(prop){
    const animate = ref(true)
    const chart : any = ref(null);
    const noData = ref(false)
    const filter : Ref<FilterProps> = ref(prop.filter)
    const store = useStore();
    const dominio_gestao : string = store.getters.layoutConfig("distribuidor.distribuidor");    
    const codNivel = store.getters.getCodNivel;
    const categories : any = ref([])
    const seriesData : any = ref([])
    const chartInstance = ref();
    const fileNameDownload = ref(fileName('CONVERSÃO_DE_PACOTES_POR_CONSULTOR',filter.value.ano,filter.value.mes))
    const title = ref(titleChart('CONVERSÃO DE PACOTES POR CONSULTOR',filter.value.ano,filter.value.mes))

    onMounted( async () => {                
      await getConversaoPacotes()
      rederChart()
    })

    const rederChart = () => {
      const options = {
        title: {
          text: title.value,
          align: 'CENTER',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: undefined,
            color: '#263238'
          },
        },
        chart: {
          type: 'bar',
          width: '100%',
          barHeight: '30%',
          height: heightDinamicoFunc(categories.value, true, 17, 19).heigth,
          toolbar: {
            export: {
              png: {
                filename: fileNameDownload.value,
              },
              svg: {
                filename: fileNameDownload.value,
              }
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetX: 40,
          style: {
            fontSize: "12px",
            colors: ["#6c7b88"],
          },
          formatter: function (value, dataPointIndex) {
            return `${value}%`
          },
        },

        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
            colors: {
              ranges: [
                {
                  from: 50,
                  to: 100,
                  color: 'var(--cor_indicadores_1)'
                },
                {
                  from: 35,
                  to: 50,
                  color: 'var(--cor_indicadores_3)'
                },
                {
                  from: 0,
                  to: 35,
                  color: 'var(--cor_indicadores_4)'
                },
              ],
            }
          }
        },
        series: [
          {
            name: 'Faturamento',
            data: seriesData.value,
            color: '#67b7dc'
          },
        ],
        xaxis: {
          labels: {
            show: false, // Oculta os rótulos do eixo x
          },
          categories: categories.value,
        },
        yaxis: {
          labels: {
            show: true,
            align: 'right',
            minWidth: 260,
            maxWidth: 270,
          },

        },
        tooltip: {
          enabled: true,
        },
        responsive: [{
          breakpoint: 430,
          options: {
            title: {
              text: 'CONVERSÃO DE PACOTES POR CONSULTOR',
              align: 'LEFT',
              margin: 0,
              offsetX: -40,
              offsetY: 0,
              floating: false,
              style: {
                fontSize: '12px',
                fontWeight: 'bold',
                fontFamily: undefined,
                color: '#263238'
              },
            },
            dataLabels: {
              offsetX: 60,
              enabled: true,
              style: {
                fontSize: "10px",
                colors: ["#6c7b88"],
              },
            },
            yaxis: {
              labels: {
                show: true,
                align: "right",
                minWidth: 0,
                maxWidth: 120,
                style: {
                  fontSize: '10px',
                },
              },
            },
          },
        },
        ],
      };

        chartInstance.value = new ApexCharts(chart.value, options);
        chartInstance.value.render();
    }
        
    watch(() => prop.filter,async (newValue) => {   

        filter.value = newValue
        fileNameDownload.value = fileName('CONVERSÃO_DE_PACOTES_POR_CONSULTOR',filter.value.ano,filter.value.mes)
        title.value = titleChart('CONVERSÃO DE PACOTES POR CONSULTOR',filter.value.ano,filter.value.mes)

        await  getConversaoPacotes()

        if (chart.value) {
          chartInstance.value.updateOptions({
            xaxis: {categories: categories.value},
            title: {
              text: title.value
            },
            chart: {
              height: heightDinamicoFunc(categories.value, true, 17, 19).heigth,
              toolbar: {
                export: {
                  png: {
                    filename: fileNameDownload.value,
                  },
                  svg: {
                    filename: fileNameDownload.value,
                  }
                }
              }
            },
            series: [
              {
                name: 'Faturamento',
                data: seriesData.value,
                color: '#67b7dc'
              },
            ],
          });
        }
    },{deep: true})

    const getConversaoPacotes = async () => {
        if(filter.value.codConcessionaria?.length){
            categories.value = []
            seriesData.value = []

            noData.value = false
            animate.value = true            

            try{
                const response = await conversaoPacotesConsultor(filter.value)  

                noData.value =  response.length > 0 ? false : true 
                animate.value = false
            
                response.forEach((item) => {
                    categories.value.push(item.nomeFantasia);
                    seriesData.value.push(parseFloat(item.revisoes));
                });
            }catch{
              animate.value = false  
            }

        }else{
            categories.value = []
            seriesData.value = []
        }                

    }
    
    return {
        dominio_gestao,
        filter,
        chart,
        categories,
        animate,
        noData
    }
}
})
