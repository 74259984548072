
import { ref,watch,defineComponent } from 'vue'
import { ContentLoader } from 'vue-content-loader';

export default defineComponent({
    name: 'Loading-name-grafico',
    components:{
        ContentLoader
    },
    props:{
        animate:{
            type: Boolean,
            required: true
        },
        textLoading:{
            type: String,
            default: 'Loading...'
        }
    },
    setup(prop){

        const animate = ref(prop.animate)   
        const defaultText = ref(prop.textLoading)

        watch(() => prop.animate, (newValue) => animate.value = newValue)        

        return {
            animate,
            defaultText
        }
    }
})

