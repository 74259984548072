
import { defineComponent , watch , ref ,Ref , PropType , onMounted , reactive } from 'vue'
import { faturamentoProdutos } from '@/services/BusinessInteligence'
import { FilterProps } from '../models'
import { useStore } from 'vuex';
import ApexCharts from 'apexcharts';
import LoadingNameGrafico from '../components/LoadingNameGrafico.vue'
import { ContentLoader } from 'vue-content-loader';
import {fileName, heightDinamicoFunc, titleChart} from '../ultils'

interface Category {
  nome: string;
  produtos: string;
}

export default defineComponent({
name: 'Faturamento',
components: {
    ContentLoader,
    LoadingNameGrafico
},
props: {
    filter: {        
        type: Object as PropType<FilterProps>,
        required: true      
    },
},
setup(prop){
    const animate = ref(true)
    const noData = ref(false)
    const chart : any = ref(null);
    const filter : Ref<FilterProps> = ref(prop.filter)
    const store = useStore();
    const codNivel = store.getters.getCodNivel;
    const dominio_gestao : string = store.getters.layoutConfig("distribuidor.distribuidor");
    const concessionariasFaturamento = reactive({
        codConcessionaria : 0,
        nomeFantasia : '',
        faturamento : 0,
        produtos : ''           
    })
    const produtos = ref()
    const categories : any = ref([])
    const seriesData : any = ref([])
    const chartInstance = ref();
    const options = ref();
    const fileNameDownload = ref(fileName('FATURAMENTO_POR_PRODUTO',filter.value.ano,filter.value.mes))
    const title = ref(titleChart('FATURAMENTO POR PRODUTOS',filter.value.ano,filter.value.mes))

    onMounted( async () => {

      if(codNivel != -1){
        await getFaturamentoProduto()
      }  
       rederChart ()            
    })

  const rederChart = () => {
    options.value = {
      title: {
        text: 'FATURAMENTO POR PRODUTOS',
        align: 'CENTER',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          fontFamily: undefined,
          color: '#263238'
        },
      },
      chart: {
        type: 'bar',
        width: '100%',
        height: heightDinamicoFunc(categories.value, false, 17, 35).heigth,
        toolbar: {
          export: {
            png: {
              filename: fileNameDownload.value,
            },
            svg: {
              filename: fileNameDownload.value,
            }
          }
        }
      },
      dataLabels: {
        offsetX: 110,
        enabled: true,
        formatter: function (value, dataPointIndex) {

          return `R$ ${value.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
          })}`
        },
        style: {
          fontSize: "12px",
          colors: ["#6c7b88"],
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        }
      },
      series: [
        {
          name: 'Faturamento',
          data: seriesData.value,
          color: '#67b7dc'
        },
      ],
      xaxis: {
        labels: {
          show: false, // Oculta os rótulos do eixo x
        },
        categories: categories.value,
      },
      yaxis: {
        labels: {
          show: true,
          align: 'right',
          minWidth: 260,
          maxWidth: 270,
        },
      },
      tooltip: {
        enabled: true,
        custom: function ({series, seriesIndex, dataPointIndex, w}) {
          const currentData = produtos.value[dataPointIndex]

          return `<div class="card shadow-sm" >
                                    <div class="fs-7 p-3 apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;"" 
                                    style=" font-weight: bold;
                                    color: #3F4254;                                                        
                                    ">
                                    ${currentData.nome ? currentData.nome : ''}
                                </div> 
                                <div class="p-3 ">
                                    <div ; class="fs-7">
                                        Faturamento: <span 
                                        style="
                                        font-weight: 600
                                        ">R$ ${series[seriesIndex][dataPointIndex].toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
          })}
                                        </span> 
                                    </div> 
                                    <div class="fs-7">Produtos: 
                                        <span 
                                        style="
                                        font-weight: 600
                                        "> ${currentData.produtos} </span></div> 
                                    </div>
                                </div>`;
        }
      },
      responsive: [{
        breakpoint: 430,
        options: {
          title: {
            text: title.value,
            align: 'LEFT',
            margin: 0,
            offsetX: -40,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: '12px',
              fontWeight: 'bold',
              fontFamily: undefined,
              color: '#263238'
            },
          },
          dataLabels: {
            offsetX: 100,
            enabled: true,
            style: {
              fontSize: "10px",
              colors: ["#6c7b88"],
            },
          },
          yaxis: {
            labels: {
              show: true,
              align: "right",
              minWidth: seriesData.value.length > 0 ? 270 : 0,
              maxWidth: 120,
              style: {
                fontSize: '10px',
              },
            },
          }
        },
      },
      ],
    };

    chartInstance.value = new ApexCharts(chart.value, options.value);
    chartInstance.value.render();

    colorLabelStatic()
  }

    //logica para mudar a cor do label abaixo do nome da concessionaria 
    const colorLabelStatic = () => {         

        const textElement : NodeListOf<SVGTSpanElement> = document.querySelectorAll('#faturamento-produto tspan ');

        textElement.forEach((textElement: SVGTSpanElement,index: number) => {
        if (index % 2 !== 0) {
            textElement.style.fill = 'var(--cor_texto_2)'; // Defina a cor do texto 
          textElement.style.fontStyle = 'italic'
        }else{
          textElement.style.fontWeight = 'bold'
        }
        }); 
    }
        
    watch(() => prop.filter,async (newValue) => {          
        filter.value = newValue
        fileNameDownload.value = fileName('FATURAMENTO_POR_PRODUTO',filter.value.ano,filter.value.mes)
        title.value = titleChart('FATURAMENTO POR PRODUTOS',filter.value.ano,filter.value.mes)

        await  getFaturamentoProduto()
        if (chart.value) {
          chartInstance.value.updateOptions({
            xaxis: {categories: categories.value},
            title: {
              text: title.value
            },
            yaxis: {
              labels: {
                show: true,
                align: "right",
                minWidth: seriesData.value.length > 0 ? 270 : 0,
                maxWidth: 260,
              },
            },
            chart: {
              height: heightDinamicoFunc(categories.value, false, 17, 35).heigth,
              toolbar: {
                export: {
                  png: {
                    filename: fileNameDownload.value,
                  },
                  svg: {
                    filename: fileNameDownload.value,
                  }
                }
              }
            },
            series: [
              {
                name: 'Faturamento',
                data: seriesData.value,
                color: '#67b7dc'
              },
            ],
          });

            colorLabelStatic()
        }

    },{deep: true})

    const getFaturamentoProduto = async () => {

        categories.value = []
        seriesData.value = []

        noData.value = false
        animate.value = true        

        try{
            const response = await faturamentoProdutos(filter.value)  

            noData.value =  response.length > 0 ? false : true         
            animate.value = false
            produtos.value = response

            response.forEach(item => {
                const produto = `P: ${item.produtos}`
                
                if(heightDinamicoFunc().innerWidth < 430){
                    categories.value.push([item.nome,produto]);
                }else{
                    categories.value.push(item.nome,produto);
                }
                seriesData.value.push(item.faturamento);
            });

        }catch{
            noData.value = true
            animate.value = false
        }
    }
    

    return {
        concessionariasFaturamento,
        dominio_gestao,
        filter,
        chart,
        categories,
        animate,
        noData
    }
}
})
