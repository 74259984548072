interface HeightDinamicoFuncReturn {
    heigth:string,
    WidthMobile: number
    innerWidth: number
}

// função para deixa mais reponsivo o grafico é controlar de todos de uma so vez
function heightDinamicoFunc(
    array: string[] = [], 
    linebreak : Boolean = true ,
    customValue : number = 17,
    customValueMobile : number = 50
) : HeightDinamicoFuncReturn {
  
    const innerWidth  = window.innerWidth // tamanho da tela, altera apenas quando da f5
    let heightDinamico = 0;
    
    const valueLineBreak =  linebreak ? 30 : customValue // caso tenha a quebra de linha na  label do grafico 
    let tamanhoDefinidoMaisItens = innerWidth < 430 ? customValueMobile : valueLineBreak // caso tiver no mobile o valor e alterado 
    const diferencaTitle = 70
    
    if (array.length == 1) {
        heightDinamico = 120;
    } else {
        if(array.length == 0){
            heightDinamico = 200
        }else{
            heightDinamico = array.length * tamanhoDefinidoMaisItens + diferencaTitle;
        }
    }

    return {
        heigth :  `${heightDinamico}px`,
        WidthMobile : innerWidth < 430 ? 0 : 260,
        innerWidth 
    };
};
const fileName = (name: string, ano: number, mes: string[] | undefined): string => {
    let fileName;
    if (mes) {
        const mesString = mes.map(mes => convertMesString(mes))
        fileName = `${name}_${ano}_${mesString.toString()} `
    } else {
        fileName = `${name}_${ano}`
    }
    return fileName
}

const titleChart = (title: string , ano:number , mes: string[] | undefined) => {
    let titleChart;
    if(mes){
        const mesString = mes.map(mes => convertMesString(mes))
        titleChart = `${title} - ${ano} - ${mesString.join(' - ').toString()} `
    }else{
        titleChart = `${title} - ${ano}`
    }

    return titleChart
}
function convertMesString(mes : any) : string{
    const meses = {
        '1': 'Janeiro',
        '2': 'Fevereiro',
        '3': 'Março',
        '4': 'Abril',
        '5': 'Maio',
        '6': 'Junho',
        '7': 'Julho',
        '8': 'Agosto',
        '9': 'Setembro',
        '10': 'Outubro',
        '11': 'Novembro',
        '12': 'Dezembro'
    }
    return meses[mes];
}

export { heightDinamicoFunc, fileName, titleChart , convertMesString }